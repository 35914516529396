// @flow

import React, { type Node } from "react";

import { Permissions } from "../../api/permissions";
import History from "../../containers/History";
import { usePermissions } from "../../hooks";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const SceneHistory = ({ id }: Props): Node => {
    const [canRead, canDownload] = usePermissions([
        Permissions.SCENE_READ,
        Permissions.SCENE_DOWNLOAD,
    ]);

    if (!canRead || !id) {
        return <NotFoundPage />;
    }

    return (
        <History
            id={id}
            label={"Scene version history - "}
            canDownload={canDownload}
            base={"scene"}
            backUrl={"/scenes"}
        />
    );
};

export default SceneHistory;
